<template>
  <div>
    <div class="section">
      <h1 class="section__title">List of Equipment</h1>
    </div>
    <div class="equipment-section">
      <div class="equipment-section__background-img-wrapper">
        <picture>
          <img
            class="our-space-banner__img"
            :src="require(`@/assets/images/locations/equipment-image.jpg`)"
            alt="our-space"
          />
        </picture>
      </div>
      <div class="img-overlay">
        <div class="section__container">
          <div class="equipment__details">
            <ul class="text--align-left">
              <li>Free Weights</li>
              <li>Treadmill</li>
              <li>Spin Bike</li>
              <li>Half Rack</li>
            </ul>
            <ul class="text--align-left">
              <li>Stepper</li>
              <li>Skipping Ropes</li>
              <li>Yoga Mat</li>
              <li>Resistance Band</li>
              <li>TV (with workout material)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "location-equipment",
};
</script>
<style lang="scss" scoped>
.equipment-section {
  position: relative;
}
.equipment-section__background-img-wrapper {
  width: 100%;
  height: 100%;
  z-index: -1;
  img {
    width: 100%;
  }
}
.img-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(39, 39, 39, 0.75);
  backdrop-filter: blur(2px);
  color: white !important;
}
.equipment__details {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  li {
    padding: 5px 0px;
    font-size: 1.25rem;
  }
}
.section__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
