<template>
  <div class="section carousel-section">
    <div class="section__container">
      <h1 class="section__title text--align-left">
        Everything fun in one place
      </h1>
      <div class="location-space-img-wrapper">
        <picture>
          <img
            class="location-space-img"
            :src="
              require(`@/assets/images/locations/${location}/location-thumbnail1.jpg`)
            "
            alt="our-space"
          />
        </picture>
        <picture class="center-location-space-img-wrapper">
          <img
            class="location-space-img"
            :src="
              require(`@/assets/images/locations/${location}/location-thumbnail2.jpg`)
            "
            alt="our-space"
          />
        </picture>
        <picture>
          <img
            class="location-space-img"
            :src="
              require(`@/assets/images/locations/${location}/location-thumbnail3.jpg`)
            "
            alt="our-space"
          />
        </picture>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "location-image-section",
  props: ["location"],
};
</script>
<style lang="scss" scoped>
.location-space-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  height: auto;
  @media only screen and (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 60px;
    .center-location-space-img-wrapper {
      margin: 0 10px;
    }
  }
}
.location-space-img {
  width: 100%;
  background: grey;
  border-radius: 3px;
  margin-top: 10px;
  @media only screen and (min-width: 640px) {
    margin-top: 0px;
  }
}
</style>
