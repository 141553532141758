<template>
  <div>
    <location-hero :isLocationReady="locationDetails.isLocationReady" />
    <location-map
      :address="locationDetails.address"
      :map="locationDetails.map"
    />
    <location-image-section :location="locationDetails.location" />
    <location-equipment v-if="windowWidth > 950" />
    <location-equipment-mobile v-else />
    <pricing-section class="pricing-section" />
  </div>
</template>

<script>
import LocationImageSection from "./LocationImageSection.vue";
import LocationHero from "./LocationHero.vue";
import LocationMap from "./LocationMap.vue";
import LocationEquipmentMobile from "./LocationEquipmentMobile.vue";
import PricingSection from "@/components/pricing/PricingSection.vue";
import LocationEquipment from "./LocationEquipment.vue";
export default {
  props: ["locationDetails"],
  name: "location-page",
  components: {
    LocationHero,
    LocationMap,
    LocationImageSection,
    LocationEquipmentMobile,
    PricingSection,
    LocationEquipment,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    mobileView() {
      return this.windowWidth <= 640;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" scoped>
.pricing-section {
  @media only screen and (min-width: 640px) {
    padding-bottom: 60px;
  }
}
</style>
