<template>
  <about-us-page />
</template>
<script>
import AboutUsPage from "@/components/about/AboutUsPage.vue";
export default {
  name: "about-us",
  components: {
    AboutUsPage,
  },
  metaInfo: {
    title: "About Us",
    meta: [
      {
        name: "description",
        content:
          "O SPACE is a revolutionary concept that aims to empower the individual with more choices and options in this day and age, centring on health and wellness.",
      },
    ],
  },
};
</script>
<style lang="scss" scoped></style>
