<template>
  <div>
    <div class="section location-text-section section--grey">
      <p>{{ address }}</p>
    </div>
    <div>
      <div class="section map-section">
        <iframe
          class="map"
          :src="map"
          allowfullscreen="true"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "location-map",
  props: ["address", "map"],
};
</script>
<style lang="scss" scoped>
.location-text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding-top: 0;
  padding-bottom: 0;
}
.map {
  border: 0;
  width: 100%;
  max-width: 1200px;
  height: 300px;
  @media only screen and (min-width: 640px) {
    height: 500px;
  }
}
</style>
