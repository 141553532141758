<template>
  <div class="section equipment-section">
    <div class="equipment-section__background-img-wrapper">
      <img
        class="equipment-section__background-img"
        :src="require(`@/assets/images/locations/equipment-mobile.png`)"
      />
    </div>
    <div class="section__container">
      <h1>List of equipment</h1>
      <ul class="equipments-wrapper text--align-left">
        <li>Free Weights</li>
        <li>Treadmill</li>
        <li>Spin Bike</li>
        <li>Half Rack</li>
        <li>Stepper</li>
        <li>Skipping Ropes</li>
        <li>Yoga Mat</li>
        <li>Resistance Band</li>
        <li>TV (with workout material)</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "location-equipment",
};
</script>
<style lang="scss" scoped>
.equipment-section__background-img-wrapper {
  width: 100%;
  height: 100%;
  background-color: #00704a;
  z-index: -1;
  position: absolute;
  bottom: 0px;
}
.equipment-section {
  position: relative;
  color: white;
}
.equipments-wrapper {
  margin-left: -20px;
  li {
    padding: 3px 0px;
  }
}
.equipment-section__background-img {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
