<template>
  <div class="section about-us-section section--grey text--align-left">
    <div class="section__container">
      <h1 class="section__title">About Us</h1>
      <div class="about-us-section__details">
        <p>
          OSPACE is a revolutionary concept that aims to empower the individual
          with more choices and options in this day and age, focusing on health
          and wellness. OSPACE combines a 24hr gym, fitness, wellness and
          technology in one dynamic space for fitness enthusiasts. We bring
          cutting edge technology to every user of our gym, ensuring comfort and
          privacy during their workout sessions.
        </p>
        <p>
          OSPACE is pay-per-use that is equipped with all the gym equipment you
          need for a good workout. It also comes with a consultation and therapy
          area that is great for a singular physiotherapist and sports masseuse
          to work on their clients. A private Yoga/Dance/Zumba/Fitness
          instructor can also utilize the space to conduct a one-to-one class or
          hold an online session for his/her students in a professional fitness
          centre setting.
        </p>
        <p>
          Whether you are an aspiring fitness entrepreneur, a physiotherapist or
          someone who just wants to achieve a healthier and fitter body, you can
          achieve your dream through OSPACE.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "about-us",
};
</script>
<style lang="scss" scoped>
.about-us-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 0;
  }
}
.about-us-section__details {
  padding-top: 30px;
  @media only screen and (min-width: 640px) {
    padding-top: 60px;
  }
}
</style>
