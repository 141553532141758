<template>
  <div class="section">
    <p>Redirecting...</p>
    <router-link to="/"> <p>Back to Home</p></router-link>
  </div>
</template>

<script>
export default {
  name: "hello-world",

  methods: {
    getOS() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        iosPlatforms = ["iPhone", "iPad", "iPod"];
      if (macosPlatforms.indexOf(platform) !== -1) {
        window.location.replace(
          "https://apps.apple.com/sg/app/ospace/id1606617283"
        );
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        window.location.replace(
          "https://apps.apple.com/sg/app/ospace/id1606617283"
        );
      } else if (/Android/.test(userAgent)) {
        window.location.replace(
          "https://play.google.com/store/apps/details?id=com.ospacesg"
        );
      } else {
        window.location.replace(
          "https://play.google.com/store/apps/details?id=com.ospacesg"
        );
      }
    },
  },
  mounted() {
    this.getOS();
  },
};
</script>

<style lang="scss" scoped>
.section {
  height: 100vh;
}
</style>
