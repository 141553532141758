<template>
  <div>
    <location-page
      :locationDetails="getLocationDetails"
      @book="handleOpenDownloadAppModal()"
    />
  </div>
</template>

<script>
import LocationPage from "@/components/location/LocationPage.vue";
export default {
  name: "Location",
  components: { LocationPage },
  data() {
    return {
      amk: {
        location: "amk",
        address: "Blk 548 AMK Ave 10 #01-2010 Singapore 560548",
        map: "https://maps.google.com/maps?q=Blk%20548%20AMK%20Ave%2010%20&t=&z=13&ie=UTF8&iwloc=&output=embed",
        isLocationReady: true,
      },
      bedoknorth: {
        location: "bedoknorth",
        address: "Blk 509 Bedok North St 3 #01-95 Singapore 460509",
        map: "https://maps.google.com/maps?q=Blk%20509%20Bedok%20North%20St%203&t=&z=13&ie=UTF8&iwloc=&output=embed",
        isLocationReady: true,
      },
      tampines: {
        location: "tampines",
        address: "Block 107 Tampines Street 11 # 01-347 Singapore 521107",
        map: "https://maps.google.com/maps?q=107%20Tampines%20St.%2011%20%20Singapore%20521107&t=&z=13&ie=UTF8&iwloc=&output=embed",
        isLocationReady: true,
      },
      tanglinHalt: {
        location: "tanglinHalt",
        address: "Blk 48 Tanglin Halt Rd #01-339 Singapore 142048 ",
        map: "https://maps.google.com/maps?q=Blk%2048%20Tanglin%20Halt%20Rd%20%20%20Singapore%20142048%20&t=&z=13&ie=UTF8&iwloc=&output=embed",
        isLocationReady: true,
      },
      metaTitle: "",
      metaAddress: "",
    };
  },
  computed: {
    getLocationDetails() {
      if (this.$route.params.location == "amk") {
        return this.amk;
      } else if (this.$route.params.location == "bedoknorth") {
        return this.bedoknorth;
      } else if (this.$route.params.location == "tampines") {
        return this.tampines;
      } else if (this.$route.params.location == "tanglin-halt") {
        return this.tanglinHalt;
      } else {
        return {};
      }
    },
  },
  mounted() {
    if (this.$route.params.location == "amk") {
      this.metaTitle = "Ang Mo Kio";
      this.metaAddress = this.amk.address;
    } else if (this.$route.params.location == "bedoknorth") {
      this.metaTitle = "Bedok North";
      this.metaAddress = this.bedoknorth.address;
    }
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        {
          name: "description",
          content:
            "Looking for a gym? Check out our space at " + this.metaAddress,
        },
      ],
    };
  },
};
</script>
