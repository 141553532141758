<template>
  <div class="nav-card-container">
    <div class="card nav-card text--align-left">
      <router-link :to="links[0].route" class="nav-card__links-wrapper top">
        <h3 class="text--no-margin">{{ links[0].title }}</h3>
        <font-awesome-icon
          :icon="['fa', 'chevron-right']"
          size="lg"
          v-if="links[0].chevron"
        />
      </router-link>
      <router-link class="nav-card__links-wrapper" :to="links[1].route">
        <h3 class="text--no-margin">{{ links[1].title }}</h3>
      </router-link>
      <router-link class="nav-card__links-wrapper bottom" :to="links[2].route">
        <h3 class="text--no-margin">{{ links[2].title }}</h3>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "nav-card",
  props: {
    links: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-card-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  @media only screen and (min-width: 640px) {
    justify-content: flex-start;
  }
}
.nav-card {
  font-family: "Chivo", sans-serif;
  border-radius: 5px;
  width: 70%;
  max-width: 300px;
  border: 3px solid #2b303a;
  background-color: #00704a;
  @media only screen and (min-width: 640px) {
    margin: 0 0 200px 100px;
  }
}
.nav-card__links-wrapper {
  height: 100%;
  padding: 20px;
  display: flex;
  color: white;
  text-decoration: none;
  &.top {
    border-bottom: 3px solid #2b303a;
    justify-content: space-between;
  }
  &.bottom {
    border-top: 3px solid #2b303a;
  }
  &:hover {
    background-color: #005539;
  }
  @media only screen and (min-width: 640px) {
    padding: 20px;
  }
}
</style>
