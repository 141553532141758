<template>
  <div class="section mission-section">
    <h1 class="section__title">Our Mission</h1>
    <div class="section__container mission-container">
      <mission
        v-for="(mission, index) in missions"
        :key="index"
        :mission="mission"
      />
    </div>
  </div>
</template>
<script>
import Mission from "./Mission.vue";
export default {
  name: "mission-section",
  components: { Mission },
  data() {
    return {
      missions: [
        {
          image: "empower",
          title: "EMPOWER",
          description:
            "Empowering our gym users and freelancers with a pay-per-use space",
        },
        {
          image: "convenience",
          title: "CONVENIENCE",
          description:
            "Simple, fuss-free booking anywhere, anytime with our mobile application",
        },
        {
          image: "privacy",
          title: "PRIVACY",
          description:
            "Train and have fun in complete privacy and safety for your peace of mind",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.mission-container {
  padding: 20px 0;
  width: 100%;
}
@media only screen and (min-width: 640px) {
  .mission-section {
    padding-bottom: 0;
  }
  .mission-container {
    padding: 60px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
