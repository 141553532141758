<template>
  <div>
    <about-us-hero />
    <about-us />
    <mission-section />
    <studio-section />
    <pricing-section />
    <our-space />
  </div>
</template>
<script>
import OurSpace from "../space/OurSpace.vue";
import StudioSection from "../studio/StudioSection.vue";
import AboutUs from "./AboutUs.vue";
import AboutUsHero from "./AboutUsHero.vue";
import MissionSection from "../mission/MissionSection.vue";
import PricingSection from "../pricing/PricingSection.vue";
export default {
  name: "about-us-page",
  components: {
    AboutUs,
    AboutUsHero,
    StudioSection,
    OurSpace,
    MissionSection,
    PricingSection,
  },
};
</script>
<style lang="scss" scoped></style>
