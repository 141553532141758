<template>
  <div class="mission-wrapper">
    <h2 class="mission__title">
      <b>{{ mission.title }}</b>
    </h2>
    <picture>
      <!-- <source
        :srcset="require(`@/assets/images/branding/AlphaLogo-navbar.webp`)"
        type="image/webp"
      /> -->
      <!-- <source
        :srcset="require(`@/assets/images/branding/AlphaLogo-navbar.png`)"
        type="image/png"
      /> -->
      <img
        class="mission-thumbnail"
        :src="require(`@/assets/images/mission/${mission.image}.png`)"
        alt="product-pic"
      />
    </picture>
    <p>
      {{ mission.description }}
    </p>
  </div>
</template>
<script>
export default {
  name: "mission",
  props: ["mission"],
};
</script>
<style lang="scss" scoped>
.mission__title {
  padding-bottom: 30px;
  color: #00704a;
}
.mission-wrapper {
  padding: 20px 10px;
  @media only screen and (min-width: 640px) {
    padding: 0;
    width: 30%;
  }
}
.mission-thumbnail {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
</style>
