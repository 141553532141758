<template>
  <div class="location-hero-section">
    <div class="location-hero-section__overlay" v-if="!isLocationReady">
      <h1>Coming Soon</h1>
    </div>
    <picture>
      <source
        :srcset="require(`@/assets/images/location-hero-img-wide.webp`)"
        media="(min-width: 2001px)"
        type="image/webp"
      />
      <source
        :srcset="require(`@/assets/images/location-hero-img-wide.jpg`)"
        media="(min-width: 2001px)"
        type="image/jpeg"
      />
      <source
        :srcset="require(`@/assets/images/location-hero-img-web.webp`)"
        media="(min-width:1600px) and (max-width: 2000px)"
        type="image/webp"
      />
      <source
        :srcset="require(`@/assets/images/location-hero-img-web.jpg`)"
        media="(min-width:1600px) and (max-width: 2000px)"
        type="image/jpeg"
      />
      <source
        :srcset="require(`@/assets/images/location-hero-img.webp`)"
        media="(max-width: 1599px)"
        type="image/webp"
      />
      <img
        class="location-hero"
        media="(max-width: 1599px)"
        :src="require(`@/assets/images/location-hero-img.jpg`)"
      />
    </picture>
  </div>
</template>
<script>
export default {
  name: "location-hero",
  props: ["isLocationReady"],
};
</script>
<style lang="scss" scoped>
.location-hero-section {
  position: relative;
}
.location-hero {
  width: 100%;
  max-height: Calc(100vh - 165px);
  margin-top: 65px;
}
.location-hero-section__overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 39, 39, 0.75);
}
</style>
