<template>
  <div class="section">
    <div class="section__container text--align-left">
      <h1>Service Agreement</h1>
      <h2>Summary of Service Agreement</h2>
      <p>1) Introduction</p>
      <p>2) Rules and Conditions</p>
      <p>3) Membership and Passes</p>
      <p>4) Payments and Fees</p>
      <p>5) Other Policies</p>
      <p>6) Contact Us</p>
      <div class="service__component">
        <h2>1) Introduction</h2>
        <p>
          Hello, welcome to OSPACE’s website and APP. By accessing this website
          and it’s mobile APP, you are agreeing to the following terms stated on
          this page. Please ensure that you have read the following terms and
          conditions carefully before registering an account with us or using
          our services. At any time and without User’s consent, OSPACE has the
          rights to unilaterally amend, modify, or change this Agreement in its
          sole discretion and without any notice or cause. By continuing to use
          the Website or mobile APP after any modification, you have agreed to
          be bound by all such changes and modifications. Hence, please
          carefully review the terms and conditions on a regular basis to
          maintain awareness of all modifications or subscribe to our website as
          we would inform you about the changes we have made.
        </p>
      </div>
      <div class="service__component">
        <h2>2) Rules and Conditions</h2>
        <p>
          The max number of people in the OSPACE at one time shall be two unless
          otherwise explicitly stated. Users must be 16 years and above to book
          and use the OSPACE facilities. Users above the age of 65 shall seek
          medical advice before using the OSPACE facilities. Each booking
          consists of 30 minutes. The user shall leave the premises once their
          booking timing is up. If a user is found to overstay his/her booking
          slots beyond 10 minutes, OSPACE reserves the rights to charge the user
          for the time till the user leaves the premises.
        </p>
        <div class="service__component__sub">
          <p>
            <b>2.1) OSPACE Rules (conduct, equipment and attire related)</b>
          </p>
          <p class="service__component__sub__point">
            <b>Equipment</b> - all equipment must be use as it is and shall not
            be use for any other purpose as it intends to be, shall any user
            found to abuse the equipment, OSPACE reserve the rights to seek
            compensation from the user to pay for the damage or in case where
            damages is not possible, a full new set shall be replaced at the
            cost of the user.<br /><br />When an equipment is spoiled or not
            usable, the user must stop using it immediately and report it to
            OSPACE. OSPACE will not be liable for any injuries or accidents
            shall the user continue to operate the equipment.
          </p>
          <p class="service__component__sub__point">
            <b>Conduct</b> - users shall not use OSPACE for any other purpose
            other than fitness related activity and physiotherapy.<br /><br />Food
            shall not be consumed on the premises and only bottled drinks are
            allowed. No alcohol or any drugs are allowed in the premises of
            OSPACE.<br /><br />No equipment shall be taken out of OSPACE without
            the permission of the OSPACE. All equipment must be placed back at
            the original position upon the end of the session. Misconduct and
            penalties of breaching rules (If any users are found to be in breach
            of the above rules, OSPACE reserved the rights to terminate the
            membership of the user immediately without any compensation. )<br /><br />In
            cases where the law is suspected to be broken, OSPACE will have the
            rights to report the user to the police or any legal entities to
            handle the issue at the cost of the user.
          </p>
          <p class="service__component__sub__point">
            <b>Belongings and attire</b> - It is strongly advisable for users to
            bring their personal towel for the usage of the OSPACE and wipe off
            the surface of the equipment after usage. Users shall also be in
            proper sport attire and shoes. Users shall also refrain from wearing
            any attire that is offensive or revealing.
          </p>
        </div>
      </div>
      <div class="service__component">
        <h2>3) Membership and Passes</h2>
        <p>
          Users can sign up for the membership via our OSPACE mobile APP at no
          cost. Currently there is only one type of membership, which is pay as
          per use.<br /><br />At any time, the membership can be cancelled by
          writing to <u>member@OSPACE.com.sg</u>. We will try to process it
          within 14 working days and send a confirmation email upon completion.
        </p>
      </div>
      <div class="service__component">
        <h2>4) Payment and Fees</h2>
        <div class="service__component__sub">
          <p>
            <b>4.1) Peak and off Peak Fees</b>
          </p>
          <p class="service__component__sub__point">
            Off Peak hours are defined from 00:00 to 06:00, and the rates are
            lower than the other hours, known as Peak hours. Please refer to the
            Mobile APP or the website for the cost as we may offer promotion or
            adjust the cost from time to time
          </p>
          <p>
            <b>4.2) Payments</b>
          </p>
          <p class="service__component__sub__point">
            Payment can only be done via the mobile APP, OSPACE for the usage of
            the facilities. Please enter the promo code before payment as we are
            unable to apply the promo code after payment has been made.<br /><br />
            A receipt will be sent to the email of the registered user when a
            session has been completed. Or alternatively, users can refer to the
            Mobile App for a summary of the booking session as a proof of
            payment and usage.<br /><br />No refunds will be given once a
            booking has been made. Shall users need to update any changes to the
            booking, please edit the booking at least 4 hours before the session
            begins. <br /><br />
            If the starting of the session is less than 4 hours, please contact
            our Customer Service for assistance, we reserve the right to impose
            an admin charge to update booking anything within 4 hours before the
            session starts.
          </p>
        </div>
      </div>
      <div class="service__component">
        <h2>5) Other Policies</h2>
        <p>
          Your data is important to us and we also comply with the Singapore
          Government law in terms of the Personal Protection Data Act 2012. A
          full document can be downloaded from this
          <router-link to="privacy">link.</router-link>
        </p>
        <p>
          OSPACE is not liable for any personal injuries, accidents, medical
          illness or death for any user when in our facilities or/and when using
          the equipment provided by us at any point of time. We do not provide
          any insurance of any kind related to the user nor the use of the
          equipment <br /><br />Users are also liable for any damage, penalties,
          fines or losses for the Equipment and the facilities itself if found
          to be the fault of the user. <br /><br />OSPACE expects the user to
          fully understand the usage and operating of the Equipment in a safe,
          careful manner. OSPACE is not responsible for teaching or guiding the
          user in the operation of these equipment. <br /><br />OSPACE provide
          the facilities as a convenience, and such Services are intended to be
          used only by those persons who are able and qualified to operate the
          Equipment on their own and who have agreed to all terms and conditions
          of this Agreement
        </p>
      </div>
      <div class="service__component">
        <h2>6) Contact Us</h2>
        <p>
          If the user has any questions or feedback regarding the use of the
          facilities, equipment, service terms provided, please contact us via
          the Mobile APP or the website. We will try to provide a reply within 7
          to 14 working days.
        </p>
        <p><b>Company: </b>Zhuo Xiang International Pte Ltd</p>
        <p><b>Email: </b>contact@ospace.com.sg</p>
        <p><b>Website: </b>www.ospace.com.sg</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServiceAgreement",

  metaInfo: {
    title: "Service Agreement",
    meta: [
      {
        name: "description",
        content: "OSPACE service agreement",
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
.service__component {
  padding-top: 20px;
}
.service__component__sub {
  @media screen and(min-width:640px) {
    padding-left: 50px;
  }
}
.service__component__sub__point {
  padding-left: 33px;
}
</style>
