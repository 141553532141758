<template>
  <div class="about-us-hero-section" :style="cssProps">
    <nav-card :links="links" />
  </div>
</template>
<script>
import NavCard from "@/components/nav/NavCard.vue";
export default {
  name: "about-us-hero",
  components: { NavCard },
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require("@/assets/images/about-us-hero-img.jpg")})`,
      },
      links: [
        {
          title: "Find a studio",
          route: "/about#studio-section",
          chevron: false,
        },
        { title: "Our rates", route: "/about#pricing-section", chevron: false },
        {
          title: "Work with us",
          route: "/about#our-space-section",
          chevron: false,
        },
      ],
    };
  },
  methods: {
    onRessize() {
      if (window.innerWidth > 640) {
        this.cssProps.backgroundImage = `url(${require("@/assets/images/about-us-hero-img.jpg")})`;
      } else {
        this.cssProps.backgroundImage = `url(${require("@/assets/images/about-us-hero-mobile-img.jpg")})`;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onRessize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onRessize);
  },
};
</script>
<style lang="scss" scoped>
.about-us-hero-section {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
